<template>
<v-container grid-list-xl fluid>
    <v-layout row wrap>
        <v-flex lg12>
            <v-card>
                <hpo-filter :headers="headers" :viewName="viewName" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
                <v-data-table fixed-header height="750px"   :headers="headers" :items="items" :options.sync="pagination" :server-items-length="total_rows" :footer-props="items_per_page" :must-sort="true" :loading="loading" :mobile-breakpoint="100">
                    <template v-slot:header="{ props }"><table-header-filter :headers="props.headers" :viewName="viewName" v-on:applyFilter="applyFilter"></table-header-filter></template>
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{ item.ID }}</td>
                            <td>{{ item.Username }}</td>
                            <td class="text-end">{{ item.Amount | currency}}</td>
                            <td>{{ item.TradeContent }}</td>
                            <td>{{ item.Method | display_value(recharge_methods) }}</td>
                            <td v-bind:style="{ 'color': get_type_color(item.Status, recharge_status_list)}">{{ item.Status | display_value(recharge_status_list) }}</td>
                            <td>{{ item.CreatedDate | display_date }}</td>
                            <td>{{ item.CreatedBy }}</td>
                            <td class="text-center">
                                <v-icon medium class="mr-2" color="green darken-3" @click="gotoDetail(item.ID)">fa-external-link-alt</v-icon>
                                <v-tooltip top v-if="adminRole">
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" :disabled="item.Status !== 2" medium class="mr-2" color="red darken-6" @click="requestCancelRecharge(item.ID)">fa-times-circle</v-icon>
                                    </template>
                                    <span>Hủy lệnh nạp tiền</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:body.append>
                        <tr class="total-summary-td">
                            <td colspan="2" class="text-center font-weight-bold">Tổng</td>
                            <td class="text-end font-weight-bold">{{ summary.Amount | currency}}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </v-flex>
        <v-dialog v-model="dialogRecharge" persistent max-width="700px">
            <v-card>
                <v-card-title>
                    <span class="title">Lệnh nạp tiền {{currentRecharge.ID > 0 ? '' : '(Tạo mới)'}}</span>
                </v-card-title>
                <v-card-text>
                    <v-layout row wrap pa-3>
                        <v-flex lg12 md12 sm12 xs12>
                            <v-autocomplete :disabled="currentRecharge.ID > 0" label="Khách hàng" v-model="currentRecharge.UID" :items="customerList" item-text="FilterName" item-value="ID" :rules="requireRules"></v-autocomplete>
                        </v-flex>
                        <v-flex lg12 md12 sm12 xs12>
                            <h-currency-input :disabled="currentRecharge.ID > 0" v-model="currentRecharge.Amount" :decimal="0" label="Số tiền" class="input-group--focused" :rules="requireRules"></h-currency-input>
                        </v-flex>
                        <v-flex lg12 md12 sm12 xs12>
                            <v-select label="Hình thức" :disabled="currentRecharge.ID > 0" v-model="currentRecharge.Method" :items="recharge_methods" item-text="name" item-value="value" :rules="requireRules"></v-select>
                        </v-flex>
                        <v-flex lg12 md12 sm12 xs12>
                            <v-textarea :disabled="currentRecharge.ID > 0" label="Ghi chú" v-model="currentRecharge.TradeContent" class="input-group--focused"></v-textarea>
                        </v-flex>
                        <v-flex lg12 md12 sm12 xs12>
                            <v-select v-if="currentRecharge.ID > 0" :disabled="currentRecharge.Status !== recharge_status.Pending || (!adminRole && !paymentRole)" v-model="editStatus" :items="recharge_status_list" item-text="name" item-value="value"></v-select>
                        </v-flex>
                    </v-layout>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogRecharge = false">Đóng</v-btn>
                    <v-btn color="blue darken-1" text @click="saveData" v-if="currentRecharge.Status == recharge_status.Pending || currentRecharge.ID == 0">Lưu lại</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
</v-container>
</template>

<script>
import { mapState } from "vuex";
import configs from "../../configs";
import Filtering from "@/components/Filtering";
import {
    sendErrorNotice
} from '@/commons/utils';
import _ from "lodash";

export default {
    components: {
        "hpo-filter": Filtering
    },
    data() {
        let dataType = configs.DATA_TYPE;
        return {
            headers: [{
                    text: "ID",
                    value: "ID",
                    filterable: true,
                    dataType: dataType["Number"]
                },
                {
                    text: "User name",
                    value: "Username",
                    filterable: true,
                    quickSearch: true,
                    dataType: dataType["String"]
                },
                {
                    text: "Số tiền",
                    value: "Amount",
                    align: "end",
                    filterable: true,
                    dataType: dataType["Number"]
                },
                {
                    text: "Nội dung",
                    value: "TradeContent",
                    filterable: true,
                    dataType: dataType["String"]
                },
                {
                    text: "Hình thức",
                    value: "Method",
                    filterable: true,
                    dataType: dataType["Enum"],
                    values: configs.RECHARGE_METHODS
                },
                {
                    text: "Trạng thái",
                    value: "Status",
                    filterable: true,
                    dataType: dataType["Enum"],
                    values: configs.RECHARGE_STATUS_LIST
                },
                {
                    text: "Ngày tạo",
                    value: "CreatedDate",
                    filterable: true,
                    dataType: dataType["Date"]
                },
                {
                    text: "Người tạo",
                    value: "CreatedBy",
                    filterable: true,
                    dataType: dataType["String"]
                },
                {
                    text: "Thao tác",
                    value: "",
                    align: "center",
                    sortable: false,
                    filterable: false
                }
            ],
            recharge_methods: configs.RECHARGE_METHODS,
            recharge_status_list: configs.RECHARGE_STATUS_LIST,
            recharge_status: configs.RECHARGE_STATUS,
            pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
            items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
            viewName: "recharge_list",
            filterConditions: [],
            quickFilterConditions: [],
            editStatus: configs.RECHARGE_STATUS.Pending,
            dialogRecharge: false,
            requireRules: [v => !!v || "Trường dữ liệu không được để trống."]
        };
    },
    watch: {
        pagination: {
            handler: function (val, oldVal) {
                let filterConditions = this.$store.state.commons.filterConditions;
                this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : []
                this.filter_data();
            },
            deep: true
        },
        currentRecharge() {
            this.editStatus = this.currentRecharge.Status;
        }        
    },
    computed: {
        ...mapState({
            customers: state => state.customer.master.data,
            items: state => state.rechargeHistory.all.data,
            total_rows: state => state.rechargeHistory.all.total,
            summary: state => state.rechargeHistory.all.summary,
            loading: state => state.rechargeHistory.loading,
            currentRecharge: state => state.rechargeHistory.selected,
        }),
        customerList() {
            return _.map(this.customers, (p) => { return {'ID': p.ID, 'Username': p.Username, 'FilterName': `[${p.ID}] ${p.Username}`}})
        },
        adminRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
        },
        paymentRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Payment) == configs.ROLE_TYPE.Payment;
        },
        csRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.CustomerService) == configs.ROLE_TYPE.CustomerService;
        },
    },
    methods: {
        filter_data: function () {
            var param = {
                pagination: this.pagination,
                filter: this.filterConditions,
                quickFilter: this.quickFilterConditions
            };
            this.$store.dispatch("rechargeHistory/getList", param);
        },
        gotoDetail: function (id) {
            this.$store.dispatch("rechargeHistory/getDetail", id);
            setTimeout(this.dialogRecharge = true, 200);
        },
        requestCancelRecharge(id) {
            window.getApp.$emit('REQUEST_DELETE_DATA', id, 'Bạn muốn hủy yêu cầu nạp tiền?');
        },
        cancelRecharge(rechargeId) {
            var selectedItem = _.find(this.items, {'ID': rechargeId});
            if(selectedItem) {
                selectedItem.Status = configs.RECHARGE_STATUS.Cancel;
                this.$store.dispatch("rechargeHistory/setDetail", {
                    data: {Status: configs.RECHARGE_STATUS.Cancel, Amount: selectedItem.Amount, UID: selectedItem.UID},
                    id: rechargeId,
                    cancel: true
                });
            }
        },
        applyFilter(filterConditions) {
            let sortBy = this.pagination.sortBy
            let sortDesc = this.pagination.sortDesc
            this.pagination = configs.PAGINATION;
            this.pagination.sortBy = sortBy;
            this.pagination.sortDesc = sortDesc;
            this.filterConditions = filterConditions;
            this.filter_data();
        },
        quickFilter(filterConditions) {
            this.quickFilterConditions = filterConditions;
            this.filter_data();
        },
        createData(username = '') {
            this.$store.commit("rechargeHistory/setDetail", {
                data: {
                    ID: 0,
                    UID: 0,
                    Method: 1,
                    Username: username,
                    Amount: 0,
                    Status: this.adminRole || this.paymentRole ? configs.RECHARGE_STATUS.Approved : configs.RECHARGE_STATUS.Pending,
                    TradeContent: ''
                }
            })
            this.dialogRecharge = true;
        },
        saveData() {
            if (this.currentRecharge.Amount <= 0 || this.currentRecharge.UID === 0 || this.currentRecharge.Method === 0) {
                sendErrorNotice(`Hãy nhập số tiền, user và hình thức nạp`);
                return;
            }

            this.currentRecharge.Status = this.editStatus;
            let customer = _.find(this.customers, {
                ID: this.currentRecharge.UID
            });
            if (customer) {
                this.currentRecharge.RechargeID = this.$store.state.authentication.user.userid;
                this.currentRecharge.Username = customer.Username;
                this.$store.dispatch("rechargeHistory/setDetail", {
                    data: {
                        ...this.currentRecharge,
                        OrgStatus: this.currentRecharge.Status,
                        Status: !this.adminRole && !this.paymentRole ? this.recharge_status.Pending : this.currentRecharge.Status
                    },
                    id: this.currentRecharge.ID
                });
                if (!this.currentRecharge.ID || this.currentRecharge.ID === 0) {
                    this.$store.commit("rechargeHistory/setDetail", {
                        data: {
                            ID: 0,
                            UID: 0,
                            Method: 1,
                            Username: '',
                            Amount: 0,
                            Status: !this.adminRole && !this.paymentRole ? this.recharge_status.Pending : this.recharge_status.Approved,
                            TradeContent: ''
                        }
                    });
                }
            } else {
                sendErrorNotice('Không tìm thấy khách hàng.')
            }
            this.dialogRecharge = false;

        },
        get_type_color(val, enums) {
            return this.$options.filters.display_color(val, enums);
        },
        getCustomerList() {
            this.$store.dispatch("customer/getCustomerList", {
                is_master: 1,
                fields: ["ID", "Username"]
            });
        },
    },
    mounted() {
        this.getCustomerList();
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_CREATE_DATA", this.createData);
        this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$on("CONFIRMED_REMOVE_DATA", id => {
            this.cancelRecharge(id);
        });
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_CREATE_DATA", this.createData);
        this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$off("CONFIRMED_REMOVE_DATA");
    }
};
</script>
